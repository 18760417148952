.yellowButton1{
  position: absolute;
  margin-left: 355px;
  margin-bottom: 6px;
}

.yellowButton2{
  position: absolute;
  margin-left: 1055px;
  margin-bottom: 6px;
}
