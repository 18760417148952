.button1{
  position: absolute;
  margin-left: 255px;
  margin-bottom: 6px;
}

.button2{
  position: absolute;
  margin-left: 255px;
  margin-bottom: 6px;
}

.button3{
  position: absolute;
  margin-left: 555px;
  margin-bottom: 6px;
}

.lnndaO {
  white-space: pre-line !important;
}